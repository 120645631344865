<template>
	<div class="ele-content">
		<eleMenu />
		<div class="ele-content-main">
			<keep-alive include="duplicate-member">
				<router-view></router-view>
			</keep-alive>
		</div>
		<div class="ele-content-right">
			<span v-if="showRight" @click="showRight = false" style="position: relative;"><Icon type="md-menu" size="40" color="blue"/></span>
			<span v-if="!showRight" @click="showRight = true" style="position: relative;"><Icon type="md-menu" size="40" color="blue"/></span>
		</div>
		<Sip style="width: 400px" v-if="showRight"/>
	</div>
</template>
<script>
// import eleHead from '../head/head.vue'
import eleMenu from '../menu/menu.vue'
import Sip from '../../views/sip/index'
export default {
	components: { eleMenu, Sip },
	data() {
		return {
			showRight:true,
		}
	},
	methods: { },
	mounted() {}
}
</script>
<style lang="less" scoped>
.ele-content {
	width: 100%;
	height: 100%;
	display: flex;
}
.ele-content-main {
	width: 100%;
	height: 100%;
	padding: 8px;
	overflow-x: auto;
	// background: #ccc;
}
</style>
